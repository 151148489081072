.body {
  display: grid;
  grid-template-columns: 44% 50%;
  grid-template-rows: 1fr;
  column-gap: 6%;
  row-gap: 40px;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 60px;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
}

.button {
  margin-left: auto;
  width: 130px;
  height: 47px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 40px;
    margin-bottom: 130px;
  }

  .button {
    margin-bottom: 0;
  }
}
