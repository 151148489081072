.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
}

.undertitle {
  color: rgb(121,121,121);
  font-size: 14px;
  margin-top: 10px;
  font-weight: 800;
}

.button-container {
  margin-top: 40px;
  margin-bottom: 40px;
}




.table {
  width: 100%;
  margin-top: 15px;
}

.table td {
  padding-right: 5px;
  padding-bottom: 5px;
}

.table thead td {
  color: rgb(121,121,121);
  font-size: 14px;
}

.table tbody td {
  font-size: 14px;
  padding-top: 10px;
}



.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
  max-width: 200px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .table tbody td {
    font-size: 12px;
  }
  .table {
    min-width: 400px;
  }
}