.body {
  margin-top: 10px;
  background-color: rgb(41,45,63);
  color: white;
  padding: 30px;
}

.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 25px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 50px;
}

.item {
  background-color: rgb(27,24,62);
  padding: 20px;
}

.item-header {
  display: block;
  width: 100%;
}

.item-name {
  font-size: 16px;
  padding-bottom: 15px;
  color: rgb(240,240,240);
  font-weight: 600;
}

.item-under_name {
  font-size: 13px;
  padding-bottom: 10px;
  color: rgb(240,240,240);
}

.item-img img {
  max-width: 100%;
}

.item-graph {
  margin-top: 10px;
  margin-bottom: 15px;
  height: 105px;
  width: 100%;
  background-color: rgb(33,44,52);
  padding-top: 0px;
}

.item-graph-pr {
  text-align: right;

}

.highcharts-credits {
  display:none;
}

.icon {
  width: 80px;
  text-align: center;
  background-color: black;
  opacity: 0.5;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 11px;
  position:absolute;
  margin-top: 10px;
  margin-left: -85px;
}

.item-stat {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.item-stat-item {
  font-size: 13px;

  &-amount {
    font-size: 13px;
  }

}

.item-stat-item div:nth-child(1) {
  color: rgb(121,121,121);
}

.item-stat-item div:nth-child(2) {
  color: white;
  margin-top: 7px;
}

.button-container {
  margin-top: 30px;
  margin-bottom: 15px;
}

.button-container-more {
  margin-bottom: 15px;
}

.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
  max-width: 180px;
  margin: 0 auto;
}

.button-more {
  font-size: 14px;
  color: white;
  text-align: center;
  border: 1px solid rgb(12,167,137);
  padding: 15px;
  max-width: 180px;
  margin: 0 auto;
}

@media (max-width: 1108px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
}


@media (max-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
}