@use "../../ui-kit/scss/variables.scss" as *;

.body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 0 auto;
  z-index: 1002;
  margin-left: 80px;
  margin-right: 30px;
}


.logo {
  margin-right: 110px;
}

.icon {
  height: 20px;
  padding-right: 10px;
  margin-top: -5px;
}

.logo img {
  max-width: 200px;
}

.item {

  font-size: 14px;
  
  & + & {
    margin-left: 32px;
  }

  &__text {
    transition: color ease-in 0.1s;
    &:hover {
      color: $green;
    }
  }

  &--active {
    color: $green;
  }
}
