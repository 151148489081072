@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 15px;
  margin-bottom: 15px;
}

.title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 10px;
}

.title-underline {
  color: rgb(111,111,111);
  font-size: 13px;
  margin-bottom: 5px;
}

.chart-block {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #292D3F;
}

.table {
  width: 100%;
}

.table td {
  padding: 8px 5px;
  font-size: 13px;
}

.is-rool {
  color: rgb(110,110,110);
  padding-left: 10px;
}

.header-title {
  background-color: #282C3D;
}
