@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 30px;
  margin-bottom: 30px;
}

.title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 20px;
}

.title-underline {
  color: rgb(111,111,111);
  font-size: 13px;
  margin-bottom: 5px;
}

.chart-block {
  background-color: #292D3F;
  padding: 20px;
  border-radius: 2px;
}


.title_declaration_title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 20px;
}



.menu {
  display: flex;
}

.menu div {
  margin-right: 20px;
}

.active {
  color: white;
  font-size: 13px;
  cursor: pointer;
}

.not_active {
  color: #7A7A7B;
  font-size: 13px;
  cursor: pointer;
}


.char-data {
  padding-top: 20px;
}