.body {
  margin-top: 40px;
  margin-bottom: 40px;
}


.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}



.title_item {
  font-size: 16px;
  color: white;
  font-weight: 800;
  margin-bottom: 25px;
}

.preview {
  font-size: 14px;
  line-height: 18px;
  color: rgb(121,121,121);
  margin-bottom: 25px;
}

.preview p {
  color: rgb(121,121,121);
}

.created_on {
  font-size: 14px;
  color: rgb(121,121,121);
}

.container_item {
  margin-bottom: 35px;
}