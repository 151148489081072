@use "../../../ui-kit/scss/variables.scss" as *;


.buttonPlace {
  margin-top: 15px;
  margin-bottom: 15px;
}

.buttonWithdrawal {
  display: inline-block;
  border-radius: 3px;
  box-shadow: 0px 0px 40px rgba(0, 192, 90, 0.35);
  background-color: $green;
  padding: 10px 20px;

  font-size: 14px;
  line-height: 10px;
  font-weight: normal;

  cursor: pointer;

  transition: all ease-out 0.1s;

  &:hover {
    background-color: $green-hover;
  }

  &:active {
    background-color: $green-active;
  }

  &--sm {
    width: 150px;
    height: 45px;

    font-size: 16px;
    line-height: 19px;
  }

  &--muted {
    background-color: #63a481;
  }
}

.withdrawalBlockPayment {
  line-height: 22px;
  font-size: 14px;
}

.withdrawalBlockPaymentOut {
  margin-top: 15px;
  font-size: 14px;
}

.accordeon-item {
  padding: 12px 20px 12px 24px;
  border-top: 3px solid rgb(60,70,99);
  cursor: pointer;


  &__text-not-subscribe {
    margin-bottom: 15px;
  }

  &:hover {
    background-color: $black-element-bg-hover;
  }

  &__date-and-time {
    font-size: 14px;
    line-height: 17px;
    color: $dark-gray;
    margin-bottom: 12px;
    margin-top: 12px;
    display: block;
    text-align: left;
  }

  &__plan-name {
    font-size: 20px;
    font-weight: 600;
  }

  &__title {
    color: white;
    margin-bottom: 10px;
  }

  &__finance-block {
    font-size: 14px;
    line-height: 18px;

    &__item {
      color: rgb(255,255,255);
    }
  }
  

  &__amount-process {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__token {
    display: flex;
    justify-content: space-between;

    &-info {
      display: flex;
    }

    &-icon-and-name {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    &-icon {
      margin-right: 9px;
    }

    &-name {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600px;
    }

    &-code {
      font-size: 12px;
      line-height: 22px;
      margin-top: 10px;
      color: $dark-gray;
    }

    &-auto-status {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gold;
      color: black;
      width: 57px;
      height: 47px;
      border-radius: 8px;

      &--true {
        opacity: 0;
      }
    }

    &-summary {
      display: flex;
      flex-direction: column;

      font-size: 20px;
      line-height: 25px;
      font-weight: 600;
    }

    &-profit {
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      margin-top: 8px;
    }
  }

  &__button {
    width: 131px;
  }
}

@media (max-width: 768px) {
  .accordeon-item {
    &__token {
      flex-direction: column;
    }
  }
}
