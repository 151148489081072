@use "../../ui-kit/scss/variables.scss" as *;

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.logo-img {
  max-width: 120px;
}

.nav {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  &__item {
    & + & {
      margin-left: 32px;
    }

    &--active {
      color: $green;
    }
  }
}

.menus {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.menu_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close {
  cursor: pointer;
  z-index: 500;
}

@media (max-width: 768px) {
  .header {
    align-items: center;
    padding: 16px 0;
  }
}



.header-wallet {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
}


.header-notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
}

@media screen and (max-width: 1000px) {
  .header-notification {
    display: none;
  }
}

.wallet-icon {
  height: 26px;
}

.notification-icon {
  height: 26px;
}

.header-plus {
  text-align: center;
  color: white;
  padding: 10px;
  font-size: 16px;
  background-color: rgb(12,167,137);
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.header-notification-number {
  text-align: center;
  color: white;
  padding: 9px 10px;
  font-size: 12px;
  background-color: rgb(41,45,63);
}



.menu {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 1000;
  width: 200px;
  font-size: 13px;
}

.menu-separator {
  height: 1px;
  border-top: 1px solid rgba(103, 116, 129, 0.25);
  width: calc(100% - 36px);
  margin: 0 auto;
}

.menu-separator-enter {
  height: 2px;
  border-top: 1px solid #FFDE59;
  width: calc(100% - 36px);
  margin: 0 auto;
}


.menu-item {
  display: flex;
  align-items: center;
  padding: 14px 18px;

  font-size: 14px;
  line-height: 20px;
  color: $dark-gray;

  cursor: pointer;

  &:hover {
    background-color: $black-element-bg-hover;
    color: $gold;

    & path {
      fill: $gold;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &--sign-out {
    &:hover {
      background-color: inherit;
    }
  }
}