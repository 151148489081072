@use "../../scss/variables.scss" as *;
@use "sass:color";

.wrapper {
  position: relative;
}

.selector {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $black-element-bg;
  border-radius: 0px;
  padding: 14px;

  color: $dark-gray;
  font-size: 14px;
  line-height: 18px;

  cursor: pointer;
  user-select: none;

  &__arrow {
    width: 20px;
    height: 20px;
  }

  &--active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--selected {
    color: #fff;
  }
}

.options {
  position: absolute;
  z-index: 5;
  left: 0;

  width: 100%;
  background-color: $black-element-bg;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  max-height: 200px!important;
  overflow: auto;
}

.option {
  transition: background-color ease-in 0.1s;
  cursor: pointer;
  padding: 14px;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    background-color: $black-element-bg-hover;
  }
}
