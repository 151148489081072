@use "../../ui-kit/scss/variables.scss" as *;

.wrapper {
  position: fixed;
  bottom: 0;
  margin: 0 -16px;

  width: 100vw;
  height: 112px;
}

.nav {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 82px;
  padding: 18px 24px;

  background-color: $black-element-bg;

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
  }

  &__button-icon {
    margin-bottom: 4px;

    &--active {
      & > path {
        fill: $green;
      }
    }
  }

  &__button-wrapper {
    & + & {
      margin-left: 24px;
    }
  }

  &__button-group {
    display: flex;
    justify-content: space-around;
  }
}

.trade {
  position: absolute;
  top: 3.45px;
  left: calc(50% - 21px);
  transform: rotate(-45deg);

  border-bottom: 6px solid $black-bg;
  border-left: 6px solid $black-bg;
  border-top: 6px solid transparent;
  border-right: 6px solid transparent;
  border-radius: 12px;

  &__button {
    width: 42px;
    height: 42px;
    box-shadow: none;
  }

  &__icon {
    transform: rotate(45deg);
  }
}
