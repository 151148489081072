.body {
  margin-top: 40px;
  margin-bottom: 40px;
}

.profile {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 25px;
}

@media screen and (max-width: 1000px) {
  .profile {
    grid-template-columns: 2fr;
  }
}

.item-profile {
  padding: 15px;
  background-color: #292D3F;
}

.item-profile-title {
  font-size: 14px;
  font-weight: 800;
}

.field-title {
  margin-top: 15px;
  color: rgb(111,111,111);
  font-size: 13px;
}

.input-container {
  width: 100%;
  margin-top: 5px;
}

.input {
  padding: 10px;
  font-size: 14px;
  height: 32px;

  &::placeholder {
    color: rgb(111,111,111);
    font-size: 13px;
    font-weight: 400;
  }

}



.textarea-container {
  width: 100%;
  margin-top: 5px;
}

.textarea {
  padding: 10px;
  font-size: 14px;
  height: 100px;
}


.button-container {
  margin-top: 40px;
  margin-bottom: 40px;
}


.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
  max-width: 200px;
  margin: 0 auto;
  cursor: pointer;
}


.button_profile {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 10px 15px;
  max-width: 200px;
  cursor: pointer;
  margin-top: 15px;
}

.socila-item {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
  vertical-align: middle;
  align-items: center;
}

.socila-item-icon img {
  width: 100%;
  margin-top: 5px;
}