@font-face {
  font-family: "Gilroy";
  src: url(../font/Gilroy-Regular.eot) format("eot"), url(../font/Gilroy-Regular.woff) format("woff"),
    url(../font/Gilroy-Regular.ttf) format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(../font/Gilroy-Medium.eot) format("eot"), url(../font/Gilroy-Medium.woff) format("woff"),
    url(../font/Gilroy-Medium.ttf) format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(../font/Gilroy-SemiBold.eot) format("eot"), url(../font/Gilroy-SemiBold.woff) format("woff"),
    url(../font/Gilroy-SemiBold.ttf) format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(../font/Gilroy-Bold.eot) format("eot"), url(../font/Gilroy-Bold.woff) format("woff"),
    url(../font/Gilroy-Bold.ttf) format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
