@use "../../ui-kit/scss/variables.scss" as *;

.menu {
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 1000;
  width: 200px;
  font-size: 13px;
}

.menu-separator {
  height: 1px;
  border-top: 1px solid rgba(103, 116, 129, 0.25);
  width: calc(100% - 36px);
  margin: 0 auto;
}

.menu-separator-enter {
  height: 2px;
  border-top: 1px solid #FFDE59;
  width: calc(100% - 36px);
  margin: 0 auto;
}


.menu-item {
  display: flex;
  align-items: center;
  padding: 14px 18px;

  font-size: 14px;
  line-height: 20px;
  color: $dark-gray;

  cursor: pointer;

  &:hover {
    background-color: $black-element-bg-hover;
    color: $gold;

    & path {
      fill: $gold;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &--sign-out {
    &:hover {
      background-color: inherit;
    }
  }
}
