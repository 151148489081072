@use "../ui-kit/scss/variables.scss" as *;

.body {
  background-color: $black-bg;
  height: 100%;
}


.app {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

@media (max-width: 768px) {
  .app {
    padding: 0 16px;
  }
}
