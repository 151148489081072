@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 30px;
  margin-bottom: 30px;
}

.title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 20px;
}

.title-underline {
  color: rgb(111,111,111);
  font-size: 13px;
  margin-bottom: 5px;
}

.table {
  width: 100%;
}

.table td {
  padding: 8px 5px;
  font-size: 13px;
}

.is-rool {
  color: rgb(110,110,110);
  padding-left: 10px;
}

.header-title {
  background-color: #282C3D;
}

.fund-prop-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.fund-prop-item-title {
  color: #7F7F7F;
  font-size: 12px;
}

.fund-prop-item {
  display:flex;
  align-items: center;
  padding: 10px;
}

.fund-prop-icon {
  max-height: 30px;
  max-width: 30px;
}

.fund-prop-item-left {
  width: 50px;
}

.chart-block {
  background-color: #292D3F;
  padding: 10px;
  border-radius: 2px;
}

.fund-prop-item-name {
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}