.body {
  margin-top: 10px;
  background-color: rgb(41,45,63);
  color: white;
  padding: 30px;
}

@media screen and (max-width: 1000px) {
  .body {
    padding: 15px;
  }
}

.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 25px;
}

.table {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .table {
    min-width: 700px;
  }
}

.table td {
  padding-right: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table thead td {
  color: rgb(121,121,121);
  font-size: 14px;
  padding-top: 14px;
  vertical-align: middle;
}

.table tbody td {
  font-size: 14px;
  padding-top: 14px;
  vertical-align: middle;
}

.warning {
  margin-top: 40px;
  color: white;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
}

.button-container {
  margin-top: 40px;
  margin-bottom: 40px;
}


.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
  max-width: 200px;
  margin: 0 auto;
}

.action {
  background-color: #DA0063;
  padding: 10px;
  text-align: center;
  max-width: 200px;
}

.action:hover {
  background-color: #B30051;
}