@use "../../scss/variables.scss" as *;

.body {
  position: relative;
  width: 100%;
}

.label {
  position: absolute;
  pointer-events: none;
  user-select: none;
  cursor: text;
  top: 17px;
  left: 12px;

  font-size: 14px;
  line-height: 19px;
  color: rgb(200,200,200);

  transition: all ease-out 0.05s;
}

.input {
  background-color: $black-element-bg;
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 18px;
  padding-left: 12px;
  
  color: white;


  &::placeholder {
    color: white;
    font-weight: 800;
  }

  &--dynamic-label {
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 17px;

      & ~ .label {
        top: 2px;

        font-size: 12px;
        line-height: 15px;
        color: #fff;
      }
    }
  }
}

.icon {
  position: relative;
  margin-left: calc(100% - 60px);
  top: -40px;
  height: 30px;
}