.body {
  margin-top: 40px;
  margin-bottom: 40px;
}

.stat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 768px) {
  .stat {
    display: block;
  }
}
