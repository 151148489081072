@use "../../ui-kit/scss/variables.scss" as *;

.wrapper {
  position: relative;
  height: 100%;
}

.body-not-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 32px;
  background-color: #2F2F48;
  padding: 5px 50px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 32px;
  
  cursor: pointer;
}

.icon {
  margin-right: 8px;

  &--active {
    & > path {
      fill: $gold;
    }
  }
}

.menu {
  position: absolute;
  top: 47.5px;
  right: -72px;
  z-index: 1000;
  width: 250px;

  &__header {
    padding: 15px 18px 10px;
  }
}

.user-icon {
  height: 20px;
}

.user-icon-container {
  padding: 10px;
  width: 40px;
  border-radius: 20px;
  height: 40px;
  text-align: center;
  background-color: rgb(41,45,63);
}

.menu-separator {
  height: 1px;
  border-top: 1px solid rgba(103, 116, 129, 0.25);
  width: calc(100% - 36px);
  margin: 0 auto;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 14px 18px;

  font-size: 14px;
  line-height: 20px;
  color: $dark-gray;

  cursor: pointer;

  &:hover {
    background-color: $black-element-bg-hover;
    color: $gold;

    & path {
      fill: $gold;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &--sign-out {
    &:hover {
      background-color: inherit;
    }
  }
}

@media (max-width: 768px) {
  .icon {
    margin-right: 0;
  }

  .body {
    margin-right: 0;
  }

  .menu {
    right: 0;
    z-index: 15;
  }
}
