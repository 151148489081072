
.font {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgb(41,45,63);
}

.content {
  background-color: rgb(4,0,39);
  padding: 30px 50px;
  width: 100%;
  max-width: 700px;
}

.logo-img {
  max-width: 220px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  flex: 1 0 auto;
  font-size: 14px;
}

.title {
  flex: 0 0 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.bodyFinal {
  height: 100%;
  margin-top: 100px;
  margin-bottom: 60px;
}

.alert_error {
  padding: 15px;
  background-color: white;
  color: red;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
}

.button {
  width: 40%;
  height: 47px;
  margin: 24px auto;
}

.button_max {
  min-width: 100px;
  max-width: 140px;
  height: 50px;
  background-color: transparent;
  border: 2px solid rgb(0 192 90 / 35%);
  color: white;
}

.button_cancel {
  width: 40%;
  height: 47px;
  margin: 24px auto;
  background-color: transparent;
  color: white;
  border: 2px solid rgb(0 192 90 / 35%);
}

.text {
  color: white;
  font-size: 16px;
  text-align: center;
}

.card {
  padding: 30px;
}

.title {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
}

.main_info {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 26px;
}

.color_green {
  font-weight: 600;
  color: #00c05a;
}

.copy_button {
  height: 30px;
  font-size: 14px;
}

.back_top {
  margin-bottom: 25px;
  font-size: 13px;
  color: rgb(200,200,200);
}

.icon_back {
  font-size: 13px;
  color: rgb(200,200,200);
}

.icon {
  margin-right: 40px;
  height: 20px;
}

.bottom_status {
  margin-top: 30px;
  margin-bottom: 20px;
  color: rgb(150,150,150);
}

.bottom_status_process {
  margin-top: 30px;
  margin-bottom: 20px;
  color: rgb(255,255,255);
}

.bottom_status_process_not_new {
  font-size: 20px;
  margin-top: 30px;
  background-color: rgb(200,200,200);
  padding: 20px;
  color: black;
  font-weight: 600;
}

@media (max-width: 768px) {
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 40px;
    margin-bottom: 130px;
  }

  .button {
    margin-bottom: 0;
  }
}

.revieve {
  font-size: 16px;
  color: rgb(117,117,117);
  text-align: center;
  margin-bottom: 20px;
  line-height: 22px;
}

.notification {
  font-size: 16px;
  color: rgb(117,117,117);
  text-align: center;
  margin-top: 20px;
}

.course {
  font-size: 16px;
  color: rgb(117,117,117);
  text-align: center;
  margin-top: 20px;
}

.input {
  text-align: center;
}

.input__wrapper {
  text-align: center;
  margin: 0 auto;
  width: 270px;
}

.input__wrapper_data {
  text-align: center;
  margin: 0;
  width: 50%;
  padding: 0px;
}

.input_class_name {
  margin: 0;
}


.input__2fa {
  text-align: center;
  margin: 0 auto;
  width: 270px;
}

.input__wrapper__center {
  width: 600px;
}

.flex-input {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-gap: 10px;
  align-items: center;
}

.input-left {

}

.input-right {

}

.input-center {
  max-width: 780px;
}

.checkbox {

}

.text {
  text-align: center;
  color: rgb(117,117,117);
  margin-bottom: 20px;
  line-height: 22px;
}



@media screen and (max-width: 1000px) {
  .content {
    padding: 25px 15px;
    width: 100%;
  }

  .input__wrapper {
    width: 100%;
  }

    

  .input__wrapper__center {
    width: 100%;
  }

    
  .flex-input {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    align-items: center;
  }

  .text {
    margin-top: 15px;
  }

}


.form_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

.under_title {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.li {
  text-align: center;
  color: rgb(117,117,117);
  margin-bottom: 10px;
}

.flex_amount {
  display: flex;
  justify-content: center;
  align-items: top;
}