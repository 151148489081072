@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 30px;
  margin-bottom: 30px;
}

.title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 20px;
}

.title-underline {
  color: rgb(111,111,111);
  font-size: 13px;
  margin-bottom: 5px;
}

.chart-block {
  background-color: #292D3F;
  padding: 20px;
  border-radius: 2px;
}


.title_declaration_title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 20px;
}



.menu {
  display: flex;
}

.menu div {
  margin-right: 20px;
}

.active {
  color: white;
  font-size: 13px;
  cursor: pointer;
}

.not_active {
  color: #7A7A7B;
  font-size: 13px;
  cursor: pointer;
}

.char-data {
  padding-top: 20px;
}


.calculator-profitability {
  padding-top: 20px;
}

.calculator-profitability-title {
  font-size: 13px;
  font-weight: 800;
  color: white;
  padding-bottom: 20px;
}

.calculator-flex {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.calculator-title {
  font-size: 13px;
  color: #7A7A7B;
}

.calculator-flex-left {
  text-align: left;
}

.calculator-flex-center {
  text-align: center;
}

.calculator-flex-right {
  text-align: right;
}

.calculator-value {
  margin-top: 10px;
  font-weight: 800;
  color: white;
  font-size: 14px;
}

.calculator-value-flex { 
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.currency {
  font-weight: 800;
  color: white;
  font-size: 14px;
  padding-left: 10px;
}

.input {
  background-color: transparent;
  color: white;
  font-weight: 800;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid white;
  padding: 5px;
  width: 100%;
  max-width: 100px;
}