@use "../../ui-kit/scss/variables.scss" as *;

.footer {
  padding: 60px 0 40px;
  border-top: 1px solid rgba(103, 116, 129, 0.25);
}

.container {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  max-width: 800px;
}

.item {
  font-size: 15px;
  font-weight: 800;
  color: rgb(88,67,99);
}
