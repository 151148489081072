@use "../../../ui-kit/scss/variables.scss" as *;
@use "../../../ui-kit/scss/variables.scss" as *;


.body {
  margin-top: 25px;
  margin-bottom: 25px;
}

.title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 10px;
}

.title-underline {
  color: rgb(111,111,111);
  font-size: 13px;
  margin-bottom: 5px;
}

.chart-block {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #292D3F;
}
