@use "../../../ui-kit/scss/variables.scss" as *;

.flex-icon-container {
  display:flex;
  margin-top: 50px;
  align-items: center;
  justify-content: space-between;
}

.flex-icon-item {
  margin-right: 30px;
  display: flex;
}

.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
}

.amount {
  color: white;
  font-weight: 800;
  font-size: 26px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.icon-circle {
  background-color: rgb(28,39,48);
  padding: 4px;
}

.icon-circle-number {
  border: 1px solid rgb(12,167,137);
  text-align: center;
  padding: 6px;
  border-radius: 20px;
  font-size: 10px;
  height: 40px;
  width: 40px;
  padding-top: 14px;
}

.container-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-left-uptitle {
  font-size: 14px;
}

.container-left-title {
  font-size: 24px;
  font-weight: 800;
  margin-top: 10px;
}

.container-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-yield {
  font-size: 14px;
  font-weight: 800;
}

.right-profit {
  font-size: 24px;
  font-weight: 800;
  margin-top: 5px;
}

.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
}

.yield-data {
  margin-right: 30px;
  text-align: center;
}

.icon {
  width: 40px;
}

.flex-icon-img {
  margin-right: 15px;
}

.top {
  color: rgb(28,39,48);
}

.flex-icon-container {
  margin-top: 25px;
  background-color: rgb(41,45,63);
  color: white;
  padding: 10px 15px;
}



.body-links {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}



.item {

  & + & {
    margin-left: 32px;
  }

  &__text {
    transition: color ease-in 0.1s;
    color: rgb(111,111,111);
    &:hover {
      color: white;
    }
  }

  &--active {
    color: white;
  }
}

.button-container {
  margin-top: 30px;
  margin-bottom: 15px;
}

.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
  max-width: 180px;
  margin: 0 auto;
}

.body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.item-itog {
  margin-left: 20px;
  font-size: 22px;
  font-weight: 800;
}

.body-item-inner{
  text-align: center;
}
