@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin: 100px 0 60px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}