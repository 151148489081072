$green: rgb(12,167,137);
$green-hover: rgb(0,150,120);
$green-active: #00863f;
$green-text: #1eb224;

$red: #d43f32;

$black-bg: rgb(4,0,39);
$black-bg-login: #292d3f;
$black-element-bg: #303445;
$black-element-bg-hover: #3e445480;

$dark-gray: #677481;

$gold: #fcc958;
$gold-hover: #ffb40d;
