@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin: 100px 0 60px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}

.table {
  width: 100%;
  margin-top: 15px;
}

.table td {
  font-size: 13px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid rgb(50,50,50);
}

.flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}

.input {
  width: 100%;
  padding: 10px;
  color: black;
}

.textarea {
  width: 100%;
  padding: 10px;
  height: 400px;
  color: black;
}

.button {
  padding: 10px;
  background-color: #0CA789;
  color: white;
  cursor: pointer;
}

