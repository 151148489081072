
.flex-icon-container {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 50px;
}

.flex-icon-item {
  margin-top: 0px;
  margin-right: 30px;
  display: flex;
}

.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
}

.amount {
  color: white;
  font-weight: 800;
  font-size: 26px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.icon-circle {
  background-color: rgb(28,39,48);
  padding: 4px;
}

.icon-circle-number {
  border: 1px solid rgb(12,167,137);
  text-align: center;
  padding: 6px;
  border-radius: 20px;
  font-size: 10px;
  height: 40px;
  width: 40px;
  padding-top: 14px;
}

.top {
  color: rgb(121,121,121);
  font-size: 14px;
  margin-top: 5px;
}

.left {
  padding-left: 10px;
}

.bottom {
  margin-top: 10px;
  color: white;
  font-weight: 800;
  font-size: 15px;
}

.button-deposit {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
}

.buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}


.button-withdrawal {
  font-size: 14px;
  color: white;
  text-align: center;
  border: 2px solid rgb(12,167,137);
  padding: 13px;
}

.button-withdrawal:hover {
  background-color: rgb(12,167,137);
}

.comission {
  text-align: right;
  font-size: 14px;
  line-height: 22px;
  color: rgb(121,121,121);
}

.block {
  margin-top: -27px;
}

.comission-number {
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: right;
  font-size: 26px;
  font-weight: 800;
}

.comission-link {
  text-align: right;
  font-size: 14px;
}


@media screen and (max-width: 1000px) {
  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block {
    margin-top: 5px;
  }

  .comission-number {
    font-size: 14px;
    text-align: left;
  }

  .comission-link {
    text-align: left;
    font-size: 13px;
  }

  .comission {
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    color: rgb(121,121,121);
  }

  .flex-icon-container {
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0px;
  }

  .flex-icon-item {
    margin-top: 30px;
    margin-right: 30px;
    display: flex;
  }

}