@use "../../ui-kit/scss/variables.scss" as *;

.font {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgb(41,45,63);
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  flex: 1 0 auto;
}

.app {
  display: flex; 
  width: 100%;
  height: 100%;
  background-color: $black-bg;
}


.content {
  background-color: rgb(4,0,39);
  padding: 30px 50px;
  max-width: 400px;
  width: 100%;
}

.content2 {
  background-color: rgb(4,0,39);
  padding: 30px 50px;
  width: 100%;
  max-width: 600px;
}



.title {
  flex: 0 0 auto;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 30px;
}

.logo-img {
  max-width: 220px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
}

.input {
  background-color: rgb(27,24,62);
  & + & {
    margin-top: 12px;
  }
}

.input_2fa_div {
  width: 450px;
}

.input_2fa {
  width: 100%;
}

.input-confirm {
  background-color: rgb(27,24,62);
  max-width: 250px;
  & + & {
    margin-top: 12px;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  width: 100%;
  margin-bottom: 30px;
}

.link {
  font-size: 13px;
  line-height: 16px;

  &:hover {
    color: $green;
  }
}

.button {
  width: 40%;
  height: 47px;
}

.button_check {
  width: 40%;
  height: 47px;
  background-color: rgb(200,200,200);
}


.disclaimer {
  flex: 0 0 auto;
  text-align: center;
  margin-bottom: 24px;
  max-width: 400px;

  &__link {
    text-decoration: underline;
    &:hover {
      color: $green;
    }
  }
}

.form_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container_input {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}


.button_cancel {
  width: 40%;
  height: 47px;
  background-color: transparent;
  color: white;
  border: 2px solid rgb(0 192 90 / 35%);
}

.text_info {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #0CA789;
}


@media (max-width: 768px) {
  .title {
    font-size: 30px;
    line-height: 37px;
  }

  .link {
    font-size: 15px;
    line-height: 18px;
  }

  .button {
    width: 100%;
    margin-bottom: 24px;
  }

  .button_cancel {
    width: 100%;
    display: none;
  }

  .form_footer {
    display: block;
  }

  .input_2fa_div {
    width: 100%;
  }

}
