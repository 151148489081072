@use "../../scss/variables.scss" as *;

.arrow {
  transition: all ease-in 0.1s;
  &--active {
    transform: rotate(-180deg);

    & > path {
      stroke: $gold;
    }
  }

  &--green {
    & > path {
      stroke: $green;
    }
  }

  &--red {
    & > path {
      stroke: $red;
    }

    &--transform {
      transform:rotate(0deg)
    }
  }

  &--dark-green {
    & > path {
      stroke: $green-text;
    }
  }

  &--gray {
    & > path {
      stroke: $dark-gray;
    }
  }
}
