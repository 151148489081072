@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin: 100px 0 60px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}

.table_setting {
  border-collapse: collapse;
  width: 100%;
}

.table_setting tr td {
  padding-right: 10px;
  padding-top: 10px;
}

.table_stat_fund {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}


.table_stat_fund td {
  padding: 15px;
  border: 1px solid rgb(200,200,200);
  color: black;
}

.fund_title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
}

.add_balance_change {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.titleTable {
  padding-bottom: 5px;
  font-size: 13px;
  color: rgb(240,240,240);
}

.delete_button {
  color: red;
  cursor: pointer;
}