.body {
  background-color: rgb(41,45,63);
  color: white;
  height: 100%;
  padding: 30px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}


@media screen and (max-width: 1000px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}


.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 25px;
}

.title-item {
  color: rgb(121,121,121);
  font-size: 13px;
}

.amount-item {
  color: white;
  font-weight: 800;
  font-size: 16px;
  margin-top: 5px;
}