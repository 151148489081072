.body {
  margin-top: 40px;
  margin-bottom: 40px;
}

.body-links {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: 800;
  margin-top: 10px;
}

.item {

  & + & {
    margin-left: 32px;
  }

  &__text {
    transition: color ease-in 0.1s;
    color: rgb(111,111,111);
    &:hover {
      color: white;
    }
  }

  &--active {
    color: white;
  }
}



