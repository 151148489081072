.body {
  display: block;
  height: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.item {
  background-color: rgb(41,45,63);
  color: white;
  height: 100%;
  padding: 30px;
  margin-bottom: 15px;
}


@media screen and (max-width: 1200px) {
  .container {
    display: block;
    clear: both;
    height: auto;
  }
  .item {
    display: block;
    height: auto;
    padding: 30px;
    margin-bottom: 15px;
  }
}




.left-column,
.right-column {
  display: flex;
  flex-direction: column;
}

.button {
  margin-left: auto;
  width: 130px;
  height: 47px;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 40px;
    margin-bottom: 130px;
  }

  .button {
    margin-bottom: 0;
  }
}
