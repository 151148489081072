@use "../../ui-kit/scss/variables.scss" as *;

.news {
  background-color: rgb(41,45,63);
  color: white;
  padding: 30px 20px;
  width: 100%;
}

.news-main {
  width: 100%;
  color: white;
  margin-top: 40px;
  margin-bottom: 60px;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 50px;
}


.container-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 50px;
}

.title {
  font-size: 14px;
  color: white;
  font-weight: 800;
  cursor: pointer;
}

.preview {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
  line-height: 20px;
  color: rgb(121,121,121);
}

.preview p {
  color: rgb(121,121,121);
}

.created_on {
  font-size: 14px;
  color: rgb(121,121,121);
}

.h1 {
  font-size: 26px;
  color: white;
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.h1-main {
  font-size: 26px;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1108px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
}


@media (max-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
}