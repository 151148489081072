.body {
  margin-top: 10px;
  background-color: rgb(41,45,63);
  color: white;
  padding: 30px;
}

.title {
  color: white;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 25px;
}

.table {
  width: 100%;
  min-width: 700px;
}

.table td {
  padding-right: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table thead td {
  color: rgb(121,121,121);
  font-size: 14px;
  vertical-align: middle;
}

.table tbody td {
  padding-top: 10px;
  font-size: 14px;
  vertical-align: middle;
}

.warning {
  margin-top: 40px;
  color: white;
  font-weight: 800;
  font-size: 14px;
  text-align: center;
}

.button-container {
  margin-top: 40px;
  margin-bottom: 40px;
}


.button {
  font-size: 14px;
  color: white;
  text-align: center;
  background-color: rgb(12,167,137);
  padding: 15px;
  max-width: 200px;
  margin: 0 auto;
}

.process-status {
  font-size: 12px;
  color: rgb(111,111,111);
}

.new {
  width: 6px;
  height: 6px;
  background-color: yellow;
  border-radius: 6px;
}

.process {
  width: 6px;
  height: 6px;
  background-color: green;
  border-radius: 6px;
}

.flex-comment {
  display: flex;
  margin-top: 10px;
  font-size: 12px;
  align-items: center;
}

.flex-comment div {
  color: rgb(111,111,111);
}

.button_out {
  height: 47px;
  margin: 24px auto;
  background-color: transparent;
  border: 2px solid rgb(0 192 90);
}

.button-more {
  font-size: 14px;
  color: white;
  text-align: center;
  border: 1px solid rgb(12,167,137);
  padding: 15px;
  max-width: 180px;
  margin: 0 auto;
}