@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 30px;
  margin-bottom: 30px;
}

.title {
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 20px;
}

.title-underline {
  color: rgb(111,111,111);
  font-size: 13px;
  margin-bottom: 5px;
}

.table {
  width: 100%;
}

.table td {
  padding: 8px 5px;
  font-size: 13px;
}

.is-rool {
  color: rgb(110,110,110);
  padding-left: 10px;
}

.header-title {
  background-color: #282C3D;
}

.fund-prop-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.fund-prop-item-title {
  color: #7F7F7F;
  font-size: 12px;
}

.fund-prop-item {
  width: 100%;
  display:flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.fund-prop-icon {
  max-height: 30px;
  max-width: 30px;
}

.fund-prop-item-right {
  width: 100px;
  text-align: right;
  font-weight: 600;
  font-size: 14px;
}

.fund-prop-item-left {
  font-size: 13px;
  color: rgb(240,240,240);
}

.fund-prop-item-left-title {
  font-size: 13px;
  color: #808080;
}

.chart-block {
  background-color: #292D3F;
  padding: 10px;
  border-radius: 2px;
}

.fund-prop-item-name {
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}


.green {
  color: #0CA789;
  font-size: 16px;
  font-weight: 800;
}


.red {
  color: #DA0063;
  font-size: 16px;
  font-weight: 800;
}

.title_declaration {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.title_declaration_img {
  padding-left: 40px;
}

.title_declaration_img img {
  height: 40px;
  width: 40px;
}

.title_declaration_title {
  font-weight: 800;
  font-size: 14px;
}

.fund-prop-item-left-value {
  color: white;
  font-size: 16px;
  font-weight: 800;
}